import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { SEO } from "../components/seo";
import Header from "../components/Header";

import partnerOsmosis from "../images/media-kit/partner-osmosis-logo.png";
import partnerCrescent from "../images/media-kit/partner-crescent-logo.png";
import partnerShade from "../images/media-kit/partner-shade-logo.png";
import partnerOmniflix from "../images/media-kit/partner-omniflix-logo.png";
import partnerStablecoin from "../images/media-kit/partner-stablecoin-logo.png";
import partnerKado from "../images/media-kit/partner-kado-logo.png";
import partnerSquid from "../images/media-kit/partner-squid-logo.png";
import partnerUmee from "../images/media-kit/partner-umee-logo.png";

import logoColor from "../images/media-kit/inter-protocol-logo-color.png";
import logoColorToken from "../images/media-kit/inter-protocol-token-logo-color.png";
import logoColorSymbol from "../images/media-kit/inter-protocol-logo-symbol-color.png";
import logoBlack from "../images/media-kit/inter-protocol-logo-black.png";
import logoBlackToken from "../images/media-kit/inter-protocol-token-logo-black.png";
import logoBlackSymbol from "../images/media-kit/inter-protocol-logo-symbol-color.png";
import logoWhite from "../images/media-kit/inter-protocol-logo-white.png";
import logoWhitToken from "../images/media-kit/inter-protocol-token-logo-white.png";
import logoWhiteSymbol from "../images/media-kit/inter-protocol-logo-symbol-color-white-bg.png";

import brandImagery1 from "../images/media-kit/brand-imagery-1.jpg";
import brandImagery2 from "../images/media-kit/brand-imagery-2.jpg";
import brandImagery3 from "../images/media-kit/brand-imagery-3.jpg";
import brandImagery4 from "../images/media-kit/brand-imagery-4.jpg";

import graphics1 from "../images/media-kit/graphics-1.jpg";
import graphics2 from "../images/media-kit/graphics-2.jpg";
import graphics3 from "../images/media-kit/graphics-3.jpg";
import graphics4 from "../images/media-kit/graphics-4.jpg";
import graphics5 from "../images/media-kit/graphics-5.jpg";
import graphics6 from "../images/media-kit/graphics-6.jpg";
import graphics7 from "../images/media-kit/graphics-7.jpg";
import graphics8 from "../images/media-kit/graphics-8.jpg";
import graphics9 from "../images/media-kit/graphics-9.jpg";
import graphics10 from "../images/media-kit/graphics-10.jpg";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage } from "gatsby-plugin-image";

const staticData = {
	seo: {
		title: "Media Kit",
	},
	section: {
		intro: {
			title: "Media Kit",
			description: `
				<p>Inter Protocol is a Cosmos-native application, built on Agoric, that powers the minting of IST - an over-collateralized,risk-managed, cryptocurrency-backed stable token for use across the interchain ecosystem.</p>
				<p>IST is designed to maintain parity with the US dollar (USD) for broad accessibility and ca2n be can be minted with bridgedDAI, USDC, and USDT using the<a href='' target='_blank'>Parity Stability Module</a>(PSM) or by locking up collateral in <a href='' target='_blank'>Vaults</a>, such as ATOM.</p>
			`,
		},
		partners: {
			title: "Current Partners",
			list: [
				{ name: "Osmosis", logo: partnerOsmosis, alt: "Osmosis logo" },
				{ name: "Crescent", logo: partnerCrescent, alt: "Crescent logo" },
				{ name: "Shade", logo: partnerShade, alt: "Shade logo" },
				{ name: "Omniflix", logo: partnerOmniflix, alt: "Omniflix logo" },
				{ name: "Stablecoin", logo: partnerStablecoin, alt: "Stablecoin logo" },
				{ name: "Kado", logo: partnerKado, alt: "Kado logo" },
				{ name: "Squid", logo: partnerSquid, alt: "Squid logo" },
				{ name: "Umee", logo: partnerUmee, alt: "Umee logo" },
			],
		},
		logos: {
			title: "Logos",
			description: `
				<p>Use the Inter Protocol marks appropriately for strong visibility</p>
				<h6>Dos:</h6>
				<ul><li>To link to inter.trade or Inter Protocol-owned social platforms</li><li>In blog posts, social media, or news articles about Inter Protocol</li><li>Use the black logos on light backgrounds</li><li>Use the white logo on dark backgrounds</li></ul>
				<h6>Don’ts:</h6>
				<ul><li>Use the Inter Protocol marks for your branding</li><li>Modify the Inter Protocol marks in any way</li><li>Integrate any Inter Protocol marks into your project’s branding</li><li>Use the Inter Protocol marks for promotional activities, indicating affiliation with the Inter Protocol website,product, or social media accounts without permission</li></ul>
			`,
			list: {
				color: { horizontal: logoColor, token: logoColorToken, symbol: logoColorSymbol },
				black: { horizontal: logoBlack, token: logoBlackToken, symbol: logoBlackSymbol },
				white: { horizontal: logoWhite, token: logoWhitToken, symbol: logoWhiteSymbol },
			},
			download: {
				label: "Download all logos",
				src: "../images/media-kit/downloads/inter-media-kit-logos.zip",
			},
		},
		colors: {
			title: "Colors",
			description: `
					<p>As Inter Protocol is a yellow world,  the brand’s main color is Amber.</p>
					<p>Black and White are used in text and logos.</p>
					<p>Gradient is used in the symbol.</p>
			`,
			list: [
				{ name: "Amber", hex: "#FEC20A", rgb: "254, 194, 10" },
				{ name: "Melrose", hex: "#705BFE", rgb: "112, 91, 254" },
				{ name: "Black", hex: "#000000", rgb: "0, 0, 0" },
				{ name: "Lavender Rose", hex: "#FF8FEB", rgb: "255, 143, 235" },
				{ name: "Candlelight", hex: "#FEE11A", rgb: "254, 225, 26" },
				{ name: "Vermilion", hex: "#FF5500", rgb: "255, 85, 0" },
			],
		},
		typography: {
			title: "Typography",
			description: `
				<p>Use Gustavo font in Inter Protocol’s visuals</p>
			`,
			download: {
				label: "Download fonts",
				src: "../images/media-kit/downloads/inter-media-kit-fonts.zip",
			},
			list: [
				{
					name: "Gustavo Bold",
					samples: ["HERO HEADER", "Title Case", "Banner text"],
					hex: "#000000",
					rgb: "0, 0, 0",
					size: 36,
					lineHeight: 1.2,
					weight: 700,
				},
				{
					name: "Gustavo Regular",
					samples: ["Body text"],
					hex: "#000000",
					rgb: "0, 0, 0",
					size: 36,
					lineHeight: 1.2,
					weight: 400,
				},
			],
		},
		brand: {
			title: "Brand Imagery",
		},
		imagery: {
			title: "Brand Imagery",
			download: {
				label: "Download images",
				src: "../images/media-kit/downloads/inter-media-kit-imagary.zip",
			},
			list: [
				{ image: brandImagery1, alt: "Inter Protocol brand imagery 1" },
				{ image: brandImagery2, alt: "Inter Protocol brand imagery 2" },
				{ image: brandImagery3, alt: "Inter Protocol brand imagery 3" },
				{ image: brandImagery4, alt: "Inter Protocol brand imagery 4" },
			],
		},
		graphics: {
			title: "Useful Graphics",
			download: {
				label: "Download images",
				src: "../images/media-kit/downloads/inter-media-kit-graphics.zip",
			},
			list: [
				{ image: graphics1, alt: "Inter Protocol graphics 1" },
				{ image: graphics2, alt: "Inter Protocol graphics 2" },
				{ image: graphics3, alt: "Inter Protocol graphics 3" },
				{ image: graphics4, alt: "Inter Protocol graphics 4" },
				{ image: graphics5, alt: "Inter Protocol graphics 5" },
				{ image: graphics6, alt: "Inter Protocol graphics 6" },
				{ image: graphics7, alt: "Inter Protocol graphics 7" },
				{ image: graphics8, alt: "Inter Protocol graphics 8" },
				{ image: graphics9, alt: "Inter Protocol graphics 9" },
				{ image: graphics10, alt: "Inter Protocol graphics 10" },
			],
		},
		connect: {
			title: "Connect",
			description: `
			<a href='mailto:info@inter.trade' target='_blank' rel='noopener noreferrer'>info@inter.trade</a>
			`,
		},
		footer: {
			title: "Download the IST media kit",
			download: {
				label: "Download",
				src: "../images/media-kit/downloads/inter-media-kit-all.zip",
			},
		},
	},
};

const MediaKitPage = ({ data }) => {
	const filterContentfulSections = (filter, onlyOne = true) => {
		const section = data.contentfulPage.sections.filter(function (section) {
			return section.internal.type === filter;
		});
		if (onlyOne) {
			return section[0];
		}
		return section;
	};

	const filterFileSections = (contentful_id) => {
		const fileSections = filterContentfulSections("ContentfulMediaKitFiles", false);
		const partners = fileSections.filter((section) => {
			return section.contentful_id === contentful_id;
		});

		if (partners.length) {
			if (partners[0].files?.length === 1) {
				return partners[0].files[0];
			}

			return partners[0].files;
		}

		return null;
	};

	const header = filterContentfulSections("ContentfulHeader");
	const partnerSection = filterFileSections("4nEk8TFDCOHzMEUW90eGVV");
	const logoSection = filterFileSections("6RgNe6o4heOusEmqULeV9x");
	const typographySection = filterFileSections("41UGp2Z9YTcWkUH1BhWdiT");
	const brandImagerySection = filterFileSections("50sDOM5ISgNe7dM0KIyVar");
	const usefulGraphicsSection = filterFileSections("1o8Fij6F2N8XBbdOmvKhem");
	const fullDownloadSection = filterFileSections("24rh8ISIieFWhCU7Qotkjj");

	return (
		<div className='media-kit-page'>
			<Helmet>
				<title>{staticData.seo.title}</title>
			</Helmet>
			<Header />

			<div className={"section media-kit-hero"} />

			<div id='intro' className={"section media-kit-intro"}>
				<div className='container container-slim'>
					<h1 className=''>{header.title}</h1>
					<div>{header.subtitle && renderRichText(header.subtitle)}</div>
				</div>
			</div>

			<div id='partners' className={"section media-kit-partners"}>
				<div className='container container-slim'>
					<h2 className=''>{staticData.section.partners.title}</h2>
					<div className='parners-grid'>
						{partnerSection.map((partner, index) => (
							<div className='partner-item' key={index}>
								<GatsbyImage className='partner-img' alt={partner.title} image={partner.gatsbyImageData} />
								<p>{partner.title}</p>
							</div>
						))}
					</div>
				</div>
			</div>

			<div id='logos' className={"section media-kit-logos"}>
				<div className='container container-slim'>
					<div className='flex flex-wrap'>
						<div className='basis-full lg:basis-4/12 xl:basis-2/5'>
							<h2 className=''>{staticData.section.logos.title}</h2>
							<div dangerouslySetInnerHTML={{ __html: staticData.section.logos.description }} />
							<a href={logoSection.publicUrl} download className='hidden lg:inline-block'>
								{staticData.section.logos.download.label}
							</a>
						</div>
						<div className='pt-8 lg:pt-0 lg:pl-10 basis-full lg:basis-8/12 xl:basis-3/5'>
							<div className='grid grid-cols-10'>
								<div className='flex items-center justify-center col-span-5'>
									{staticData.section.logos.list.color?.horizontal && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[130px]' src={staticData.section.logos.list.color.horizontal} alt='Protocol color logo' />
										</div>
									)}
								</div>
								<div className='flex items-center justify-center col-span-3'>
									{staticData.section.logos.list.color?.token && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[130px]' src={staticData.section.logos.list.color.token} alt='Protocol color logo' />
										</div>
									)}
								</div>
								<div className='flex items-center justify-center col-span-2'>
									{staticData.section.logos.list.color?.symbol && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[110px]' src={staticData.section.logos.list.color.symbol} alt='Protocol color logo' />
										</div>
									)}
								</div>
							</div>

							<div className='grid grid-cols-10'>
								<div className='flex items-center justify-center col-span-5'>
									{staticData.section.logos.list.color?.horizontal && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[130px]' src={staticData.section.logos.list.black?.horizontal} alt='Protocol color logo' />
										</div>
									)}
								</div>
								<div className='flex items-center justify-center col-span-3'>
									{staticData.section.logos.list.black?.token && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[130px]' src={staticData.section.logos.list.black.token} alt='Protocol color logo' />
										</div>
									)}
								</div>
								<div className='flex items-center justify-center col-span-2'>
									{staticData.section.logos.list.black?.symbol && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[110px]' src={staticData.section.logos.list.black.symbol} alt='Protocol color logo' />
										</div>
									)}
								</div>
							</div>

							<div className='relative grid grid-cols-10 py-1 mt-2'>
								<div className='absolute w-full h-full scale-105 bg-black rounded-full -z-10' />
								<div className='z-0 flex items-center justify-center col-span-5'>
									{staticData.section.logos.list.white?.horizontal && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[130px]' src={staticData.section.logos.list.white.horizontal} alt='Protocol color logo' />
										</div>
									)}
								</div>
								<div className='flex items-center justify-center col-span-3'>
									{staticData.section.logos.list.white?.token && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[130px]' src={staticData.section.logos.list.white.token} alt='Protocol color logo' />
										</div>
									)}
								</div>
								<div className='flex items-center justify-center col-span-2'>
									{staticData.section.logos.list.white?.symbol && (
										<div className='flex'>
											<img className='w-auto h-full max-h-[110px]' src={staticData.section.logos.list.white.symbol} alt='Protocol color logo' />
										</div>
									)}
								</div>
							</div>

							<div className='grid grid-cols-10 mt-5 gap-x-3'>
								<div className='flex items-start justify-center col-span-5'>
									<div className='flex'>
										<div className='text-xs leading-3 text-center md:leading-5 md:text-lg'>Use in horizontal contexts</div>
									</div>
								</div>
								<div className='flex items-start justify-center col-span-3'>
									<div className='flex'>
										<div className='text-xs leading-3 text-center md:leading-5 md:text-lg'>Use for token</div>
									</div>
								</div>
								<div className='flex items-start justify-center col-span-2'>
									<div className='flex'>
										<div className='text-xs leading-3 text-center md:leading-5 md:text-lg'>Use for social or accent</div>
									</div>
								</div>
							</div>
						</div>
						<a download href={logoSection?.publicUrl} className='inline-block mt-24 lg:hidden'>
							Download all logos
						</a>
					</div>
				</div>
			</div>

			<div id='colors' className={"section media-kit-colors"}>
				<div className='container container-slim'>
					<div className='flex flex-wrap'>
						<div className='basis-full lg:basis-4/12 xl:basis-2/5'>
							<h2 className=''>{staticData.section.colors.title}</h2>
							<div dangerouslySetInnerHTML={{ __html: staticData.section.colors.description }} />
						</div>
						<div className='pt-12 lg:pt-6 lg:pl-10 basis-full lg:basis-8/12 xl:basis-3/5'>
							<div className='colors-grid'>
								{staticData.section.colors.list.map((color, index) => (
									<div className='colors-item' key={index}>
										<svg className='colors-img' xmlns='http://www.w3.org/2000/svg' width={148} height={148} viewBox='0 0 148 148' fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M74 148C114.869 148 148 114.869 148 74C148 33.1309 114.869 0 74 0C33.1309 0 0 33.1309 0 74C0 114.869 33.1309 148 74 148ZM74 104C90.5685 104 104 90.5686 104 74C104 57.4314 90.5685 44 74 44C57.4315 44 44 57.4314 44 74C44 90.5686 57.4315 104 74 104Z'
												fill={color.hex}
											/>
										</svg>
										<div>
											<div className='colors-title'>{color.name}</div>
											<div className='colors-hex'>{color.hex}</div>
											<div className='colors-rgb'>{color.rgb}</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id='typography' className={"section media-kit-typography"}>
				<div className='container container-slim'>
					<div className='flex flex-wrap'>
						<div className='basis-full lg:basis-4/12 xl:basis-2/5'>
							<h2 className=''>{staticData.section.typography.title}</h2>
							<div dangerouslySetInnerHTML={{ __html: staticData.section.typography.description }} />
							<a download href={typographySection?.publicUrl} className='inline-block'>
								{staticData.section.typography.download.label}
							</a>
						</div>
						<div className='pt-12 lg:pt-4 lg:pl-10 basis-full lg:basis-8/12 xl:basis-3/5'>
							<div className='typography-grid'>
								{staticData.section.typography.list.map((typography, index) => (
									<div className='typography-item' key={index}>
										<div>
											<div className='typography-title' style={{ color: typography.hex, fontWeight: typography.weight }}>
												{typography.name}
											</div>
											<div className='typography-samples'>
												{typography.samples.map((sample, index) => (
													<div className='sample' key={index} style={{ color: typography.hex, fontWeight: typography.weight }}>
														{sample}
													</div>
												))}
											</div>
										</div>
										<div>
											<div className='typography-hex'>Text Color: {typography.hex}</div>
											<div className='typography-rgb'>Text Color (rgb): {typography.rgb}</div>
											<div className='typography-size'>Size: {typography.size}px</div>
											<div className='typography-line-height'>Line Height: {typography.lineHeight}</div>
											<div className='typography-weight'>Font Weight: {typography.weight}</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id='imagery' className={"section media-kit-imagery"}>
				<div className='container container-slim'>
					<div className='flex flex-wrap'>
						<div className='basis-full'>
							<h2 className=''>{staticData.section.imagery.title}</h2>
							<a download href={brandImagerySection?.publicUrl} className='inline-block'>
								{staticData.section.imagery.download.label}
							</a>
						</div>
						<div className='pt-12 lg:pt-14 basis-full'>
							<div className='imagery-grid'>
								{staticData.section.imagery.list.map((item, index) => (
									<div className='imagery-item' key={index}>
										<img className='imagery-img' src={item.image} alt={item.alt} />
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id='graphics' className={"section media-kit-graphics"}>
				<div className='container container-slim'>
					<div className='flex flex-wrap'>
						<div className='basis-full'>
							<h2 className=''>{staticData.section.graphics.title}</h2>
							<a download href={usefulGraphicsSection?.publicUrl} className='inline-block'>
								{staticData.section.graphics.download.label}
							</a>
						</div>
						<div className='pt-12 lg:pt-14 basis-full'>
							<div className='graphics-grid'>
								{staticData.section.graphics.list.map((item, index) => (
									<div className='graphics-item' key={index}>
										<img className='graphics-img' src={item.image} alt={item.alt} />
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id='contact' className={"section media-kit-contact"}>
				<div className='container container-slim'>
					<div className='flex flex-wrap'>
						<div className='basis-full'>
							<h2 className=''>{staticData.section.connect.title}</h2>
							<div dangerouslySetInnerHTML={{ __html: staticData.section.connect.description }} />
						</div>
					</div>
				</div>
			</div>

			<div id='footer' className={"section media-kit-footer"}>
				<div className='container container-slim'>
					<div className='text-container'>
						<h2 className='flex uppercase'>{staticData.section.footer.title}</h2>
						<a style={{ padding: `0rem 3.125rem` }} download href={fullDownloadSection?.publicUrl} className='inline-block'>
							{staticData.section.footer.download.label}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MediaKitPage;

export const Head = () => <SEO />;

export const query = graphql`
	{
		contentfulPage(contentful_id: { eq: "48lDABeh51aYIbT9Mpu1U5" }) {
			sections {
				... on ContentfulHeader {
					title
					subtitle {
						raw
					}
					callToActionText
					callToActionUrl
					isCallToActionDisabled
					extraTitle
					extraSubtitle
					internal {
						type
					}
				}
				... on ContentfulMediaKitFiles {
					name
					files {
						gatsbyImageData
						url
						publicUrl
						title
					}
					internal {
						type
					}
					contentful_id
				}
			}
		}
	}
`;
